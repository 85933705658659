/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import styled from "styled-components";

export default function PageBanner() {
  return (
    <Styles>
      <img src="/images/banner.jpg" alt="" />
    </Styles>
  );
}

const Styles = styled.div`
  img {
    width: 100%;
  }
`;
