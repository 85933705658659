export const TALENT_SLIDE_SLICK = {
  slidesToShow: 3,
  slidesToScroll: 1,
  fade: false,
  arrows: false,
  autoplay: false,
  infinite: true,
  draggable: true,
  swipeToSlide: true,
  touchMove: true,
  swipe: true,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },

    {
      breakpoint: 479,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
  ]
};

export const ACTIVITY_SLIDE_SLICK = {
  slidesToShow: 2,
  slidesToScroll: 1,
  arrows: false,
  autoplaySpeed: 5000,
  centerMode: true,
  centerPadding: '0',
  fade: false,
  autoplay: true,
  infinite: true,
  draggable: true,
  swipeToSlide: true,
  touchMove: true,
  swipe: true,
  dots: false,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },

    {
      breakpoint: 650,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

export const ACTIVITY_AD_SLIDE_SLICK = {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplaySpeed: 5000,
  centerMode: true,
  centerPadding: '0',
  fade: false,
  autoplay: true,
  infinite: true,
  draggable: true,
  swipeToSlide: true,
  touchMove: true,
  swipe: true,
  dots: false,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },

    {
      breakpoint: 650,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

export const BRAND_SLIDE_SLICK = {
  slidesToShow: 6,
  slidesToScroll: 1,
  arrows: false,
  centerMode: true,
  centerPadding: '0',
  fade: false,
  autoplay: true,
  infinite: true,
  draggable: true,
  swipeToSlide: false,
  touchMove: false,
  swipe: false,
  dots: false,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },

    {
      breakpoint: 479,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

export const DETAIL_IMG_SLIDE_SLICK = {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  centerMode: true,
  centerPadding: '0',
  dots: false,
  fade: false,
  autoplay: false,
  infinite: true,
  draggable: false,
  swipeToSlide: false,
  touchMove: false,
  swipe: false
};
