import React from "react";
import styled, { css } from "styled-components";
import Button from "../../UI/Button";
import { Link } from "react-router-dom";

const colors = [
  "#FF005C",
  "#C603BE",
  "#FF5C00",
  "#9B74A1",
  "#40889F",
  "#C86833",
  "#785793",
  "#2EA1E1",
  "#01BD79",
  "#FF7060",
  "#FF7060",
  "#9732E7",
  "#97BB30",
  "#EE1757",
  "#1EAA3D",
  "#BF770C",
];

export const ListSection = () => {
  return (
    <Styles className="bs-container">
      <Title>Danh sách dự thi Đợt 2</Title>
      <Time>Từ 9h 22/11/2022 - đến 22h 29/11/2022</Time>
      <div className="list-content">
        {Array(27)
          .fill("")
          .map((item, ind) => {
            const color = colors[ind % 18];
            return (
              <Item key={ind + 100} color={color}>
                <div className="item-content">
                  <img
                    className="student-image"
                    src="/images/students-2.png"
                    alt="student"
                  />
                  <div className="text">Bảng</div>
                  <Hexagon background={color}>{ind + 1}</Hexagon>
                </div>
                <Link to={`bang/${ind + 1}`}>
                  <Button className="item-button">Bình chọn ngay</Button>
                </Link>
              </Item>
            );
          })}
      </div>
    </Styles>
  );
};

const Styles = styled.div`
  margin-top: 70px;
  margin-bottom: 100px;
  .list-title {
    margin-bottom: 56px;
  }
  .list-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 40px 30px;
  }

  @media only screen and (max-width: 991px) {
    .list-content {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  @media only screen and (max-width: 767px) {
    .list-content {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media only screen and (max-width: 480px) {
    .list-content {
      grid-template-columns: 1fr;
    }
  }
`;

const Item = styled.div`
  border-radius: 12px;
  min-height: 160px;
  border: 1px solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  .item-content {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 700;
    background: #f8f8f8;
    z-index: 1;
    img {
      width: 60px;
    }
  }

  ::before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 8px;
    background: #f8f8f8;
  }

  ${({ color }) => css`
    border: 1px solid ${color};

    ::before {
      border: 0.5px solid ${color}50;
    }
    .text {
      color: ${color};
      padding: 0 4px;
    }
  `}

  .item-button {
    border-radius: 12px;
    font-size: 18px;
    font-weight: 700;
    height: 48px;
    transform: translate(-50%, 35%);
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 1;
    white-space: nowrap;
  }
`;

const Hexagon = ({ children, ...props }) => {
  return (
    <SHexagon {...props}>
      <span>{children}</span>
    </SHexagon>
  );
};

const SHexagon = styled.div`
  min-width: 48px;
  min-height: 44px;
  clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
  position: relative;
  ::before {
    content: "";
    position: absolute;
    top: 1px;
    bottom: 1px;
    right: 1px;
    left: 1px;
    background: white;
    clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
  }

  ::after {
    content: "";
    position: absolute;
    top: 3px;
    bottom: 3px;
    right: 3px;
    left: 3px;
    background: red;
    clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
  }
  span {
    z-index: 1;
  }

  ${({ background }) => css`
    background: ${background};
    ::after {
      background: ${background};
    }
  `}
`;

const Title = styled.h2`
  position: relative;
  text-align: center;
  max-width: 350px;
  left: 7.5px;
  top: 0px;
  font-family: "Baloo_2";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  color: #153860;
  margin: 0 auto;
`;

const Time = styled.h5`
  position: relative;
  text-align: center;
  max-width: 350px;
  left: 7.5px;
  top: 0px;
  font-family: "Baloo_2";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #153860;
  margin: 0 auto;
  margin-bottom: 100px;

  ::before {
    content: "";
    background: none;
    background-image: url("/images/Vector_5.png");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 333px;
    height: 56px;
    left: 5px;
    top: 33px;
    position: absolute;
  }
  ::after {
    content: "";
    background: none;
    background-image: url("/images/Vector_6.png");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 50px;
    height: 41px;
    left: 310px;
    top: 0px;
    position: absolute;
  }
`;
