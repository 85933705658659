export const INTRO1_EN = `
<div class="reward-content">
                                <div class="reward-part">
                                    <div class="item">
                                        <h3>Miss Universe Vietnam 2019</h3>
                                        <p class="desc">
                                        After 10 years of waiting, the one and only beauty contest for male in Viet Nam has come back, attracting thousands of contestants all over the country, all competing for the title "Miss Universe Vietnam 2019".</p>
                                        <p class="desc"><b>Miss Universe Vietnam 2019</b> has marked an important milestone as the first and the only beauty contest for male in Vietnam, to honour the beauty and talent of the people of Vietnam.
                                        </p>
                                          <p class="desc">&nbsp;</p>

                                        <h4><b>The goal</b></h4>
                                        <p class="desc">The competition aims to find a representative, an influencer among the young generation with age from 18 to 32, who honour the great characteristics of Vietnamese men. Dynamic, creative, and talented,<b> Miss Universe Vietnam 2019</b> will represent the Vietnamese men to compete in the international beauty playground as well as introduce the beauty and value of Vietnam to worldwide friends.</p>
                                        <p class="desc">In order to encourage people to take on a sustainable lifestyle as well as community-building activities, the
                                            <b>Miss Universe Vietnam Charity Fund</b>was founded. Along with 4 gratitude houses and 100 wheelchairs donation to the people in need, the organization also participate and host various social activities, including blood donation events incorporation with the RedCross Vietnam. For each contestant contributing in the Miss Universe Vietnam 2019 competition, the Broad of Directors will donate 500,000 VND to the Charity Fund.
                                        </p>
                                          <p class="desc">&nbsp;</p>

                                        <h4><b>Broad of directors</b></h4>
                                        <p class="desc">
                                           -    <b>Leading Media &amp; Leading Stars</b> – Organization unit
                                    </p>
                                     <p class="desc">-   Mister Manhunt International <b>Truong Ngoc Tinh</b> - Head of the Broad of Director.
                                    </p>
                                     <p class="desc">-   Mister International 2018 <b>Trinh Bao</b> - Ambassador of the Miss Universe Vietnam and the Miss Universe Vietnam Charity Fund.
                                     </p>
                                     <p class="desc">&nbsp;</p>
                                     <h4><b>Judges</b></h4>
                                     <p class="desc"> The contest has gathered honourable judges and coaches, namely Phuc Nguyen - Head coach from Leading media founder, Cory Dung Tran - Designer and Co-founder, Dr Nguyen Duc Tuyen - Co-founder... and others pretigous names of the beauty arena.
                                     </p>
                                     <p class="desc">   -  Miss Ao Dai - Dam Luu Ly</p>
                                     <p class="desc"> -  Miss - Thu Hoai</p>
                                     <p class="desc">-  Photographer - Trinh Quoc Huy</p>
                                     <p class="desc">-  Journalist, poetry - Nguyen Phong Viet</p>
                                     <p class="desc"> -  Director - Ta Nguyen Phuc</p>
                                     <p class="desc">- Vietnam Idol champion - Nguyen Trong Hieu</p>
                                     <p class="desc">- Host – MC Kieu Ngan</p>
                                     <p class="desc">- Golden prize of Vietnam Supermodel 2009 - Do Nguyen Hoang Long</p>
                                     <p class="desc">- Champion of International Supermodel 2018 - Duong Nguyen Kha Tran</p>

                                     <p class="desc">- "Million-view" Director - Huynh Phuc Thanh Nhan</p>
                                     <p class="desc">&nbsp;</p>
                                     <h4><b>Prize</b>
                                     </h4>
                                     <p class="desc"> The total prize value is up to 2.3 billion VND in cash and in kind.</p>
                                     <p class="desc"> -  <b>First prize</b>: 1 billion VND</p>
                                     <p class="desc"> - <b>Second prize</b>: 500 million VND</p>
                                     <p class="desc"> - <b>Third prize</b>: 300 million VND</p>
                                     <p class="desc"> - <b>5 sub prizes</b>: Mr Fitness, Mr Community, Mr Sport, Mr Bravery, Mr People’s choice: Each prize values 100 million VND
                                     </p>
                                     <p class="desc">&nbsp;</p>
                                     <h4><b>Contest</b></h4>
                                     <p class="desc">One difference in Miss Universe Vietnam 2019 contest is that all activities will be livestream on <a href="https://www.youtube.com/channel/UCHLDxq8gS7V7hfanhkB4X9Q"><b>Youtube</b></a> and <a href="https://www.facebook.com/MTVN2019/"><b>the contest's Fanpage</b></a>. Audiences and enthusiasts can accompany, support, and interacts with their favourite contestants all along the show.
                                     </p>
                                     <p class="desc">Truly honest and full of surprises, <b>Miss Universe Vietnam 2019</b>is expected to be the best beauty contest for male. The contestants must win the heart of both the judges and the audience to claim the highest title - "Miss Universe Vietnam 2019".</p>
                                     <p class="desc"><b>*Preliminary round: </b></p>
                                     <p class="desc">- The South: May 12, 2019, in Ho Chi Minh City.<br>
                                        - The North: June 16, 2019, in Hanoi.
                                    </p>
                                    <p class="desc"><b>*Final round:
                                    </b>
                                </p>
                                <p class="desc">- 24 qualified contestants from all over the country will participate in a training camp, led by the top experts of the field from both inside and outside of the country. They will be judged in the Online Round to find the Top 11 contestants, who will advance in the next round of the contest. The Online Round consists of 5 sub-contests: Mr Fitness, Mr Community, Mr Sport, Mr Bravery, and Mr People’s choice.
                                </p>

                                <p class="desc">- 12 contestants (Top 11 contestants and 1 lucky contestant with the most vote from the audience) will compete in the Final Night which will take place at Vung Tau on September 14, 2019.
                                </p>

                                <p class="desc">&nbsp;</p>
                                <h4>
                                   Note:
                                </h4>
                                <p class="desc">
                                    *Contestants <b>DO NOT</b> have to pay any fees to participate in the contest.
                                </p>
                                <p class="desc">
                                    *The competition DOES NOT prior the height criteria but the overall beauty of physical appearance and the contribution in social activities.

                                </p>
                                <p class="desc">
                                   *Contestants that own a title from other competition will advance directly in the Final Round.
                               </p>

                               <p class="desc">&nbsp;</p>
                               <p class="desc">
                                  <b> Hotline: 0916.454.449 (Mr Phuc)</b>
                              </p>
                          </div>
                                </div>
                            </div>
`;

export const INTRO2_EN = `
<div class="organizational-content">

                                <div class="organizational-item">
                                    <p class="desc">Organization unit</p>
                                    <div class="bs-row ">
                                       <div class="">
                                        <h4>LEADING MEDIA &amp; LEADING STARS  - HOME OF BEAUTY </h4>
                                        <p>With a team of various domestic and foreign experts in the field, Leading Media & Leading Starts members have claimed numerous titles of world-class beauty contests.</p>
                                    </div>
                                </div>
                                <div class="item-content">
                                    <div class="bs-row row-xs-15 row-center">
                                        <div class="bs-col xs-50-15">
                                            <div class="item">
                                                <img src="/images/logo-leading-star.jpg" alt="">
                                                <a href="#"></a>
                                                <div class="clearfix"></div>
                                            </div>
                                        </div>
                                         <div class="bs-col xs-50-15">
                                            <div class="item">
                                                <img src="/images/logo-leading-media.jpg" alt="">
                                                <a href="#"></a>
                                                <div class="clearfix"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="organizational-item">
                                <p class="desc">Co-organizers</p>
                                <div class="item-content">
                                    <div class="bs-row row-xs-15 row-center">
                                        <div class="bs-col sm-33-15 xs-50-15">
                                            <div class="item" style="height: 123px;">
                                                <img src="/images/organi_item3.gif" alt="">
                                                <a href="#"></a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
`;

export const INTRO3_EN = `
<div class="judge-content">
                                    <p class="desc italic"><b>Miss Universe Vietnam 2019 </b>- The one and only beauty contest for male in Vietnam has gathered numerous prestigious judges and beauty coaches from both inside and outside of the country.</p>

                                    <h2 class="title">Judges</h2>
                                    <div class="content">
                                        <div class="bs-row row-sm-15">
                                             <div class="bs-col md-33-15 sm-50-15">
                                                <div class="item">
                                                    <div class="img">
                                                        <div class="ImagesFrame" style="background-position: center top -8px; background-repeat: no-repeat; background-image: url(&quot;https://miss.bvote.vn/images/bgk/hh-dam-luu-ly2.jpg&quot;);">
                                                            <a class="ImagesFrameCrop0 tall">
                                                                <img src="https://miss.bvote.vn/images/bgk/hh-dam-luu-ly2.jpg" alt="Hoa hậu Áo dài Đàm Lưu Ly" style="margin-top: 59px;" class="tall">
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="text">
                                                        <p class="judge__name bold">DAM LUU LY</p>
                                                        <p class="desc">Miss Ao Dai</p>
                                                    </div>
                                                </div>
                                            </div>
                                             <div class="bs-col md-33-15 sm-50-15">
                                                <div class="item">
                                                    <div class="img">
                                                        <div class="ImagesFrame" style="background-position: center top -2px; background-repeat: no-repeat; background-image: url(&quot;https://miss.bvote.vn/images/bgk/2.jpg&quot;);">
                                                            <a class="ImagesFrameCrop0 tall">
                                                                <img src="https://miss.bvote.vn/images/bgk/2.jpg" alt="TRƯƠNG NGỌC TÌNH" style="margin-top: 66px;" class="tall">
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="text">
                                                        <p class="judge__name bold">TRUONG NGOC TINH</p>
                                                        <p class="desc">Mister Manhunt International
</p>
                                                    </div>
                                                </div>
                                            </div>
                                               <div class="bs-col md-33-15 sm-50-15">
                                                <div class="item">
                                                    <div class="img">
                                                        <div class="ImagesFrame" style="background-position: center top 5px; background-repeat: no-repeat; background-image: url(&quot;https://miss.bvote.vn/images/bgk/4.jpg&quot;);">
                                                            <a class="ImagesFrameCrop0 tall">
                                                                <img src="https://miss.bvote.vn/images/bgk/4.jpg" alt="TRƯƠNG NGỌC TÌNH" style="margin-top: 33px;" class="tall">
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="text">
                                                        <p class="judge__name bold">PHUC NGUYEN</p>
                                                        <p class="desc">Head coach</p>
                                                    </div>
                                                </div>
                                            </div>
                                             <div class="bs-col md-33-15 sm-50-15">
                                                <div class="item">
                                                    <div class="img">
                                                        <div class="ImagesFrame" style="background-position: center top 3px; background-repeat: no-repeat; background-image: url(&quot;https://miss.bvote.vn/images/bgk/3.jpg&quot;);">
                                                            <a class="ImagesFrameCrop0 tall">
                                                                <img src="https://miss.bvote.vn/images/bgk/3.jpg" alt="TRƯƠNG NGỌC TÌNH" style="margin-top: 33px; margin-left: 9px;" class="tall">
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="text">
                                                        <p class="judge__name bold">TRINH BAO</p>
                                                        <p class="desc">Mister International 2018</p>
                                                    </div>
                                                </div>
                                            </div>
                                             <div class="bs-col md-33-15 sm-50-15">
                                                <div class="item">
                                                    <div class="img">
                                                        <div class="ImagesFrame" style="background-position: center top -5px; background-repeat: no-repeat; background-image: url(&quot;https://miss.bvote.vn/images/bgk/thu-hoai.jpg&quot;);">
                                                            <a class="ImagesFrameCrop0 tall">
                                                                <img src="https://miss.bvote.vn/images/bgk/thu-hoai.jpg" alt="Hoa hậu phu nhân người Việt thế giới Thu Hoài" style="margin-top: 32px;" class="tall">
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="text">
                                                        <p class="judge__name bold">THU HOAI</p>
                                                        <p class="desc">Mrs Vietnam International</p>
                                                    </div>
                                                </div>
                                            </div>
                                             <div class="bs-col md-33-15 sm-50-15">
                                                <div class="item">
                                                    <div class="img">
                                                        <div class="ImagesFrame" style="background-position: center top 4px; background-repeat: no-repeat; background-image: url(&quot;https://miss.bvote.vn/images/bgk/trinh-quoc-huy-2.jpg&quot;);">
                                                            <a class="ImagesFrameCrop0 tall">
                                                                <img src="https://miss.bvote.vn/images/bgk/trinh-quoc-huy-2.jpg" alt="Nhiếp ảnh gia Trịnh Quốc Huy" style="margin-top: 4px;" class="wide">
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="text">
                                                        <p class="judge__name bold">TRINH QUOC HUY</p>
                                                        <p class="desc">Photographer</p>
                                                    </div>
                                                </div>
                                            </div>
                                             <div class="bs-col md-33-15 sm-50-15">
                                                <div class="item">
                                                    <div class="img">
                                                        <div class="ImagesFrame" style="background-position: center top 2px; background-repeat: no-repeat; background-image: url(&quot;https://miss.bvote.vn/images/bgk/nguyen-phong-viet.jpg&quot;);">
                                                            <a class="ImagesFrameCrop0 tall">
                                                                <img src="https://miss.bvote.vn/images/bgk/nguyen-phong-viet.jpg" alt="Nhà báo, nhà thơ Nguyễn Phong Việt" style="margin-left: 8px; margin-top: 34px;" class="tall">
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="text">
                                                        <p class="judge__name bold">Nguyen Phong Viet</p>
                                                        <p class="desc">Journalist, writer</p>
                                                    </div>
                                                </div>
                                            </div>
                                             <div class="bs-col md-33-15 sm-50-15">
                                                <div class="item">
                                                    <div class="img">
                                                        <div class="ImagesFrame" style="background-position: center top -2px; background-repeat: no-repeat; background-image: url(&quot;https://miss.bvote.vn/images/bgk/ta-nguyen-phuc-2.jpg&quot;);">
                                                            <a class="ImagesFrameCrop0 tall">
                                                                <img src="https://miss.bvote.vn/images/bgk/ta-nguyen-phuc-2.jpg" alt="TẠ NGUYÊN PHÚC" style="margin-top: 34px; margin-left: 7px;" class="tall">
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="text">
                                                        <p class="judge__name bold">TA NGUYEN PHUC</p>
                                                        <p class="desc">Director</p>
                                                    </div>
                                                </div>
                                            </div>




                                             <div class="bs-col md-33-15 sm-50-15">
                                                <div class="item">
                                                    <div class="img">
                                                        <div class="ImagesFrame" style="background-position: center top -3px; background-repeat: no-repeat; background-image: url(&quot;https://miss.bvote.vn/images/bgk/nguyen-trong-hieu-2.jpg&quot;);">
                                                            <a class="ImagesFrameCrop0 tall">
                                                                <img src="https://miss.bvote.vn/images/bgk/nguyen-trong-hieu-2.jpg" alt="Quán quân Việt Nam Idol Nguyễn Trọng Hiếu" style="margin-top: 25px;" class="tall">
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="text">
                                                        <p class="judge__name bold">NGUYEN TRONG HIEU</p>
                                                        <p class="desc">Champion of Vietnam Idol</p>
                                                    </div>
                                                </div>
                                            </div>
                                             <div class="bs-col md-33-15 sm-50-15">
                                                <div class="item">
                                                    <div class="img">
                                                        <div class="ImagesFrame" style="background-position: center top 3px; background-repeat: no-repeat; background-image: url(&quot;https://miss.bvote.vn/images/bgk/do-nguyen-hoang-long.jpg&quot;);">
                                                            <a class="ImagesFrameCrop0 tall">
                                                                <img src="https://miss.bvote.vn/images/bgk/do-nguyen-hoang-long.jpg" alt="Giải vàng Siêu Mẫu Việt Nam Đỗ Nguyễn Hoàng Long" style="margin-top: 25px;" class="tall">
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="text">
                                                        <p class="judge__name bold">DO NGUYEN HOANG LONG</p>
                                                        <p class="desc">Golden prize of Vietnam Supermodel 2009</p>
                                                    </div>
                                                </div>
                                            </div>
                                             <div class="bs-col md-33-15 sm-50-15">
                                                <div class="item">
                                                    <div class="img">
                                                        <div class="ImagesFrame" style="background-position: center top 0px; background-repeat: no-repeat; background-image: url(&quot;https://miss.bvote.vn/images/bgk/duong-nguyen-kha-trang.jpg&quot;);">
                                                            <a class="ImagesFrameCrop0 tall">
                                                                <img src="https://miss.bvote.vn/images/bgk/duong-nguyen-kha-trang.jpg" alt="Quán quân Siêu mẫu quốc tế 2018 DƯƠNG NGUYỄN KHẢ TRANG" style="margin-top: 36px;" class="tall">
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="text">
                                                        <p class="judge__name bold">DUONG NGUYEN KHA TRANG</p>
                                                        <p class="desc">Champion of International Supermodel 2018</p>
                                                    </div>
                                                </div>
                                            </div>

                                             <div class="bs-col md-33-15 sm-50-15">
                                                <div class="item">
                                                    <div class="img">
                                                        <div class="ImagesFrame" style="background-position: center top 3px; background-repeat: no-repeat; background-image: url(&quot;https://miss.bvote.vn/images/bgk/huynh-phuc-thanh-ngan-2.jpg&quot;);">
                                                            <a class="ImagesFrameCrop0 tall">
                                                                <img src="https://miss.bvote.vn/images/bgk/huynh-phuc-thanh-ngan-2.jpg" alt="Đạo diễn Huỳnh Phúc Thanh Ngân" style="margin-top: 25px;" class="tall">
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="text">
                                                        <p class="judge__name bold">HUYNH THUC THANH NHAN</p>
                                                        <p class="desc">Director</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

`;

export const INTRO4_EN = `
<div class="reward-content">

                                    <div class="reward-part">
                                        <h3 class="title">1. Official title of the contest</h3>
                                        <div class="item">
                                            <p class="item__title">a. Miss Universe Vietnam 2019</p>
                                            <p class="desc">The contestant with the highest score in the finale will be awarded the title of <b>Miss Universe Vietnam 2019.</b>.</p>
                                            <ul class="reward-list">
                                                <li class="reward-list__item">- Prize: 1,000,000,000 VND (Including cash and in kind).</li>
                                                <li class="reward-list__item">- The Miss Universe Vietnam Staff. </li>
                                                <li class="reward-list__item">- Certificate from the organizer.</li>
                                            </ul>
                                        </div>
                                        <div class="item">
                                            <p class="item__title">b. The 1st runner-up</p>
                                            <p class="desc">The contestant with the 2nd highest score in the finale will be awarded the title of <b>2nd Miss Universe Vietnam 2019.</b></p>
                                            <ul class="reward-list">
                                                <li class="reward-list__item">- Prize: 500,000,000 VND (Including cash and in kind).</li>
                                                <li class="reward-list__item">- The 1st runner-up Staff.</li>
                                                <li class="reward-list__item">- Certificate from the organizer.</li>
                                            </ul>

                                        </div>
                                        <div class="item">
                                            <p class="item__title">c. The 2nd runner-up</p>
                                            <p class="desc"></p>
                                            <ul class="reward-list">
                                                <li class="reward-list__item">The contestant with the 3rd highest score in the finale will be awarded the title of <b>2nd Miss Universe Vietnam 2019.</b></li>
                                                <li class="reward-list__item">- 300,000,000 VND (Including cash and in kind).</li>
                                                <li class="reward-list__item">- The 2nd runner-up Staff </li>
                                                <li class="reward-list__item">- Certificate from the organizer.
                                                </li>
                                            </ul>

                                        </div>

                                    </div>
                                    <div class="reward-part">
                                        <h3 class="title">2. Other titles</h3>
                                        <div class="item">
                                            <p class="desc">Winners of sub-contests will receive 100,000,000 VND prize in cash along with certificates from the Broad of Directors. Sub-contests include: </p>
                                            <ul class="reward-list list-space">
                                                <li class="reward-list__item">- Mister Fitness
                                                </li>
                                                <li class="reward-list__item">- Mister Community</li>
                                                <li class="reward-list__item">- Mister Sport
                                                </li>
                                                <li class="reward-list__item">- Mister Bravery</li>
                                                <li class="reward-list__item">- Mister People's Choice</li>

                                            </ul>
                                            <p class="desc">Contestants will be awarded points with a rate of 50% from the judges and coaches and 50% from the audience vote.
                                            </p>

                                        </div>

                                    </div>
                                    <div class="reward-part">
                                        <h3 class="title">3. Benefits </h3>
                                        <div class="item">
                                            <p class="desc">- Top 3 contestants will participate in training camp, preparing for the international competitions held by Leading Media such as <b>Mister International</b>, <b>Manhunt International</b>, <b>Mister Global</b>....
                                            </p>
                                             <p class="desc">- Top 3 contestants will have the opportunity to participate in a movie from <b> Fortune Projects </b> with the value of up to  300 million VND
                                             </p>
                                            <p class="desc">
- Top 3 contestants will be the representatives of the cosmetic brand <b> Kho Thi.</b> The value of the contract is up to 700 million VND (including 100 million VND in cash & skincare package, worth 600 million VND). </p>
                                            <p class="desc">- Awarded contestants will receive the corresponding prize from the Broad of Director
                                            </p>
                                            <p class="desc">- Participate in advertising and newspapers with approved content.</p>
                                            <p class="desc">- Participate in charity events and social activities conducted by the Broad of Directors during the time of holding the title.
                                            </p>
                                            <p class="desc">- Participate in press releases and other activities of the  Broad of Directors.</p>
                                        </div>

                                    </div>
                                </div>

`;

export const SCHEDULE1_EN = `
<div class="reward-content">

                                    <div class="reward-part">
                                        <div class="item">
                                            <p class="item__title bold">How to participate: </p>
                                            <ul class="reward-list">
                                                <li class="reward-list__item">- Apply online via <a href="http://mistervn.bvote.vn/">BVote website</a>: </li>
                                                <li class="reward-list__item">- Apply online via <a href="https://www.facebook.com/LeadingMedia0181/">Leading Media fanpage</a>:</li>

                                                <li class="reward-list__item">- Sent application form directly to the address No.557 Dien Bien Phu street, Ward 25, Binh Thanh District.</li>

                                            </ul>
                                        </div>
                                        <div class="item">
                                            <p class="item__title bold">Preliminary round</p>
                                            <p class="desc">a. The South</p>
                                            <p class="desc">Date: May 12, 2019</p>
                                            <p class="desc">Location: Ho Chi Minh City</p>
                                            <p class="desc">b. The North:</p>
                                            <p class="desc">Date: Jun 16, 2019</p>
                                            <p class="desc">Location: Hanoi</p>

                                        </div>
                                        <div class="item">
                                            <p class="item__title bold">Form</p>
                                            <p class="desc">Round 1:</p>
                                            <ul class="reward-list">
                                                <li class="reward-list__item">- Complete the required procedures and receive the uniform. </li>
                                            <li class="reward-list__item">- Take body measurement and participate in the training camp.</li>
                                            <li class="reward-list__item">- Perform in groups with uniform.</li>
                                        </ul>


                                            <p class="desc">Round 2:</p>

                                            <ul class="reward-list">
                                                <li class="reward-list__item">- Perform individually with sport outfit.</li>
                                            <li class="reward-list__item">- Interview directly with the Judges and select the Coach.</li>

                                        </ul>

                                        <br>


                                    </div>

                                    <div class="item">
                                        <p class="item__title bold">Result</p>
                                        <ul class="reward-list">
                                            <li class="reward-list__item">- The judges will select 24 qualified contestants to advance in the next round. </li>

                                        </ul>
                                    </div>
                                     <p class="desc"><b>Hotline: 0916.454.449 (Mr Phuc)</b></p>

                                </div>
                            </div>

`;

export const SCHEDULE2_EN = `
<div class="reward-content">

                                    <div class="reward-part">
                                       <p class="desc">All 24 contestants will compete in the Online Round to advance in the next round.</p>
                                       <div class="item">
                                           <p class="item__title bold">a. Mister Fitness </p>
                                           <p class="desc">- Date: July 16, 2019</p>
                                           <p class="desc">-   Detail:</p>
                                           <ul class="reward-list">
                                            <li class="reward-list__item">+ All 24 contestants will perform in front of the judges and coaches. Top 16 contestants will then compete in the next round.
                                            </li>
                                        </ul>
                                        <p class="desc">- Prize: The contestant with the best performance will receive the title "Mister Fitness."</p>
                                    </div>
                                    <div class="item">
                                       <p class="item__title bold">b. Mister Community</p>
                                       <p class="desc">- Date: July 30, 2019</p>
                                       <p class="desc">- Detail:  </p>
                                       <ul class="reward-list">
                                        <li class="reward-list__item">+ The top 16 contestants will participate in social activities such as environmental protection, charity, and donation events. Top 14 contestants will continue in the next round.
                                        </li>
                                    </ul>
                                    <p class="desc">- Prize: The contestant with the best performance will receive the title "Mister Community".</p>

                                </div>
                                <div class="item">
                                   <p class="item__title bold">c. Mister Sport </p>
                                   <p class="desc">- Date: August 13, 2019</p>
                                   <p class="desc">- Detail: </p>
                                   <ul class="reward-list">
                                    <li class="reward-list__item">+ All 14 contestants will participate in sport activities such asracing, jogging, sailing, etc. Top 12 contestants will continue in the next round.
                                    </li>
                                </ul>
                                <p class="desc">- Prize: The contestant with the best performance will receive the title "Mister Sport".</p>

                            </div>
                            <div class="item">
                               <p class="item__title bold">d. Mister Bravery</p>
                               <p class="desc">- Date: August 26, 2019</p>
                               <p class="desc">- Detail:</p>
                               <ul class="reward-list">

                                 <li class="reward-list__item">+ Top 12 contestants will participate in a reality show which consists of survival challenges on the island. Top 11 contestants will advance in the next round.</li>
                                 <li class="reward-list__item">+  One eliminated contestant from the previous rounds with the highest vote will make a comeback, along with the Top 11 contestants to participate in the Final Night. </li>

                             </ul>
                             <p class="desc">- Prize: The contestant with the best performance will receive the title "Mister Bravery". </p>

                         </div>
                         <div class="item">
                            <p class="item__title bold">e. Mister People’s choice</p>
                               <p class="desc">- Date: July 16 - September 14, 2019 (consists of several rounds)</p>
                               <p class="desc">- Detail:</p>
                               <ul class="reward-list">

                                <li class="reward-list__item">+ Corresponding to each of the remaining online rounds, the audience will accompany the contestants via BVote online voting portal by voting for their favourite candidates. The judges will base on a number of votes to decide which contestants will continue in the next round.</li>
                                <li class="reward-list__item">+ The contestant with the highest votes will receive the title "Mister's People choice".
                                </li>

                            </ul>
                            <p class="desc">- Voting mechanism: 1 Vote on Facebook = 1 point,  1 Vote via BVote = 5 points.</p>
                            <p class="desc">- Prize: The contestant with the best performance will receive the title "Mister People’s choice".</p>

                        </div>
                        <div class="item">
                           <p class="item__title bold">Hotline: 0916.454.449 (Mr Phuc)</p>

                       </div>
                   </div>
               </div>

`;

export const SCHEDULE3_EN = `
<div class="reward-content">

                                    <div class="reward-part">
                                     <p class="desc">Live on TV.
                                     </p>
                                     <div class="item">
                                         <p class="item__title bold">Date: September 14, 2019
                                         </p>
                                     </div>
                                     <div class="item">
                                         <p class="item__title bold">Intro:
                                         </p>
                                         <ul class="reward-list">
                                            <li class="reward-list__item">- The Top 11 contestant and 1 comeback contestants will compete in the final round.</li>
                                            <li class="reward-list__item">- The judges will be based on the contestant's performances along with the votes from the audiences to decide who is the next "Miss Universe Vietnam 2019".</li>
                                            <li class="reward-list__item">- Detail:</li>
                                            <li class="reward-list__item">+ Traditional costume round: 12 contestants will wearing designed traditional costumes and perform on stage.</li>
                                            <li class="reward-list__item">+ Ao Dai round: 12 contestants will wearing Ao Dai and perform on stage.</li>
                                            <li class="reward-list__item">+ Swimsuit/Sport outfit round: 12 contestants will wearing swimsuit/sports outfit and perform on stage.</li>
                                            <li class="reward-list__item">+ Vest and Q&A: 12 contestants will wearing vests and perform on stage. The judges will select top 5 contestants based on their performance to answer the final questions.
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="item">
                                     <p class="item__title bold">Prize:</p>
                                     <p class="desc">The contestant with the best performance will receive the title "Miss Universe Vietnam 2019".</p>


                                 </div>
                                 <div class="item">
                                     <p class="item__title bold">Hotline: 0916.454.449 (Mr Phuc)
                                     </p>

                                 </div>
                             </div>
                         </div>

`;

export const INTRO5_EN = `
                    <div class="reward-content">
                                <div class="reward-part">
                                    <div class="item">
                                    <p class="desc" style="font-size: 18px">The most favorite contestant of NEU Glamor 2019 has been officially named: </p>
                                    <h3 style="text-align:center">TRAN HOANG BAO DAM - 031</h3>
                                        <p class="desc" style="font-size: 18px"> 
                                        Congratulations BAO DAM has become the most favorite face audience through the online voting portal BVote, with overwhelming 35563 points.
                                        </p>
                                          <p class="desc" style="font-size: 18px"> 
                                          Bao Dam will receive a prize of VND 50,000,000 in cash along with a brand website and a communication solution worth VND 200,000,000 from the sponsor Bytesoft Vietnam.
                                        </p>
                                         </p>
                                          <p class="desc" style="font-size: 18px"> 
                                        Thank you to the audience for watching and supporting the BVote voting port and 30 contestants in Miss Universe Vietnam 2019. 
                                        </p>
                                    </div>
                                </div>
                            </div>

`
