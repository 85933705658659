/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import Slider from "react-slick";
import styled from "styled-components";
import { BRAND_SLIDE_SLICK } from "../../../config/slide";
import { Title } from "../Contestant/Contestant";

export default class SectionBrand extends Component {
  next = () => {
    this.slider.slickNext();
  };

  previous = () => {
    this.slider.slickPrev();
  };

  render() {
    return (
      <Styles className="section-brand">
        <div className="bs-container">
          <div className="bs-row">
            <div className="bs-col">
              <div className="module module-brand">
                <div className="module-content">
                  <Title className="title">
                    <FormattedMessage id="sponsor" />:
                  </Title>
                  <div className="brand-control">
                    <button
                      className="brand__btn next__btn"
                      onClick={this.previous}
                      type="button"
                    />
                    <button
                      className="brand__btn prev__btn"
                      onClick={this.next}
                      type="button"
                    />
                  </div>
                  <Slider
                    ref={(c) => (this.slider = c)}
                    {...BRAND_SLIDE_SLICK}
                    className="brand-slide"
                    data-aos="fade-up"
                  >
                    <div className="slide-item">
                      <div className="item">
                        <img src="/images/slice-1.png" alt="" />
                      </div>
                    </div>
                    <div className="slide-item">
                      <div className="item">
                        <img src="/images/slice-2.gif" alt="" />
                      </div>
                    </div>

                    <div className="slide-item">
                      <div className="item">
                        <img src="/images/slice-3.png" alt="" />
                      </div>
                    </div>
                    <div className="slide-item">
                      <div className="item">
                        <img src="/images/slice-4.png" alt="" />
                      </div>
                    </div>
                    <div className="slide-item">
                      <div className="item">
                        <img src="/images/slice-5.png" alt="" />
                      </div>
                    </div>
                    <div className="slide-item">
                      <div className="item">
                        <img src="/images/slice-6.png" alt="" />
                      </div>
                    </div>
                    <div className="slide-item">
                      <div className="item">
                        <img src="/images/slice-7.png" alt="" />
                      </div>
                    </div>

                    <div className="slide-item">
                      <div className="item">
                        <img src="/images/slice-8.png" alt="" />
                      </div>
                    </div>
                    <div className="slide-item">
                      <div className="item">
                        <img src="/images/slice-9.png" alt="" />
                      </div>
                    </div>
                    <div className="slide-item">
                      <div className="item">
                        <img src="/images/slice-10.png" alt="" />
                      </div>
                    </div>
                    <div className="slide-item">
                      <div className="item">
                        <img src="/images/slice-11.png" alt="" />
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Styles>
    );
  }
}

const Styles = styled.section`
  &.section-brand .module-brand .module-content .brand-slide {
    margin-top: 50px;
  }

  &.section-brand .module-brand .module-content .brand-control .brand__btn {
    top: 68%;
  }
`;
