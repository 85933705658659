import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { FormattedMessage } from "react-intl";
import { ContestantsContext } from "../../../App";
import ContestantCard from "../../Partials/ContestantCard";
import styled from "styled-components";
import { Item } from "../../UI/Item";
export default function SectionEvent() {
  const { contestants: listAll } = useContext(ContestantsContext);

  const [empty, setEmpty] = useState(false);
  const [filter, setFilter] = useState("");

  const onInputChange = (e) => {
    const { value } = e.target;

    setEmpty(false);
    setFilter(value);
  };

  const filteredList = filter
    ? listAll.filter((c) => c.code.includes(filter))
    : listAll;

  return (
    <SectionEventStyle>
      <section className="section-event">
        <div className="bs-container">
          <div className="bs-row">
            <div className="bs-col">
              <div className="module module-event">
                <div className="module-header" data-aos="fade-up">
                  <h2 className="title">
                    <FormattedMessage id="index.contestants" />
                  </h2>
                </div>
                <div className="bs-row">
                  <div
                    style={{
                      position: "relative",
                      "margin-left": "auto",
                      width: "100%",
                      maxWidth: "250px",
                    }}
                  >
                    <span className="mini-title">Tìm kiếm thí sinh</span>
                    <input
                      style={{
                        paddingRight: "90px",
                        textAlign: "center",
                      }}
                      value={filter}
                      onChange={onInputChange}
                      placeholder="Nhập số báo danh"
                      maxLength={3}
                    />
                    <i
                      className="fas fa-search"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "0%",
                        transform: "translate(-90%,-50%) rotateY(180deg)",
                        right: "10px",
                        opacity: "0.5",
                      }}
                    ></i>
                  </div>
                </div>
                <div className="module-content">
                  <div className="tab-content">
                    <div className="tab-item active" id="tab1">
                      <div className="bs-row row-xs-15">
                        {(!empty &&
                          filteredList &&
                          filteredList.map((el, index) => (
                            <Item
                              // stt={el && el.code}
                              // key={el._id}
                              // contestantId={el && el.contestantId}
                              rank={index + 1}
                              miss={el}
                              // totalVote={el && el.totalPoint}
                            />
                          ))) || (
                          <div
                            className="bs-col lg-25-15 md-33-15 xs-50-15"
                            style={{
                              margin: "auto",
                              textAlign: "center",
                            }}
                          >
                            Không có
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </SectionEventStyle>
  );
}
const SectionEventStyle = styled.div`
  .section-event {
    background: none;
    .module-event {
      .module-header:after {
        display: none;
      }
      .module-header {
        .title {
          position: relative;
          max-width: 317px;
          left: 7.5px;
          top: 0px;
          margin-left: auto;
          margin-right: auto;
          font-family: "Baloo_2";
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 150%;
          color: #153860;
        }
        .title:before {
          background: none;
          background-image: url("../images/Vector_5.png");
          background-position: top center;
          background-repeat: no-repeat;
          background-size: contain;
          width: 333px;
          height: 56px;
          left: 28.5px;
          top: 33px;
          position: absolute;
        }
        .title:after {
          background: none;
          background-image: url("../images/Vector_6.png");
          background-position: top center;
          background-repeat: no-repeat;
          background-size: contain;
          width: 50px;
          height: 41px;
          left: 291.5px;
          top: 0px;
        }
      }
      .mini-title {
        position: absolute;
        width: 113px;
        height: 21px;
        font-family: "Be Vietnam Pro";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #c1c3c5;
        top: 0%;
        left: 0%;
        transform: translate(1%, -109%);
      }
    }
    .module-event {
      .module-content {
        .tab-content {
          .tab-item {
            .bs-row {
              margin-top: 10px;
              gap: 31px 42px;
              justify-content: center;
            }
          }
        }
      }
    }
    input::placeholder {
      font-family: Be Vietnam Pro;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #c1c3c5;
    }
  }

  .section-event:after,
  .section-event:before {
    display: none;
  }
`;
