import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

@font-face {
  font-family: Be Vietnam Pro;
  src: url('/fonts/BeVietnamPro-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: Be Vietnam Pro;
  src: url('/fonts/BeVietnamPro-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: Be Vietnam Pro;
  src: url('/fonts/BeVietnamPro-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Be Vietnam Pro;
  src: url('/fonts/BeVietnamPro-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: Be Vietnam Pro;
  src: url('/fonts/BeVietnamPro-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Be Vietnam Pro;
  src: url('/fonts/BeVietnamPro-ExtraBold.ttf');
  font-weight: 800;
}

@font-face {
  font-family: Be Vietnam Pro;
  src: url('/fonts/BeVietnamPro-Black.ttf');
  font-weight: 900;
}

@font-face {
  font-family: Baloo_2;
  src: url('/fonts/Baloo2-Regular.ttf');
  font-weight: 400;
}
@font-face {
  font-family: Baloo_2;
  src: url('/fonts/Baloo2-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: Baloo_2;
  src: url('/fonts/Baloo2-SemiBold.ttf');
  font-weight: 600;
}
@font-face {
  font-family: Baloo_2;
  src: url('/fonts/Baloo2-Bold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: Baloo_2;
  src: url('/fonts/Baloo2-ExtraBold.ttf');
  font-weight: 800;
}

@font-face {
  font-family: Be Vietnam Pro_Bold;
  src: url('/fonts/BeVietnamPro-Regular.ttf');
  /* font-weight: 700; */
}

@font-face {
  font-family: Be Vietnam Pro_Light;
  src: url('/fonts/BeVietnamPro-Light.ttf');
  /* font-weight: 300; */
}
@font-face {
  font-family: Wylie;
  src: url('/fonts/SVN-WylieVoigen.otf');
  /* font-weight: 300; */
}
*{
  box-sizing: border-box;
}

ul{
  list-style: none;
}

a{
  text-decoration: none;
  color: unset;
}

html{
  display:block;
  height: 100%;
  margin:0;
  padding: 0;
  overflow-x: hidden;
}

body{
  background-color: white;
  height: 100%;
  min-height: 100vh;
  margin: 0;
  font-family: Be Vietnam Pro, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  color: #1F2839;
  /* overflow-x: hidden; */
  position: relative;
}

#root{
  height: 100%;
}

::-webkit-scrollbar-track{
	background-color: #fff;
}

::-webkit-scrollbar{
	width: 4px;
	background-color: #e5e5e5;
  height: 4px;
}

body::-webkit-scrollbar{
  width: 10px;
}

::-webkit-scrollbar-thumb{
	border-radius: 10px;
	background-color: #e5e5e5;
  width: 10px;
  height: 4px;
}

@media only screen and (max-width: 767px){
  body::-webkit-scrollbar{
    width: 0;
  }
}
`;

export const theme = {
  primary: "#268CE3",
  dark_primary: "#00796B",
  light_primary: "#B2DFDB",
  inverted_text: "#FFFFFF",
  accent: "#448AFF",
  pink: "#E91E63",
  orange: "#FF9800",
  amber: "#ffc107",
  light_purple: "#ba68c8",
  primary_text: "#3A4E65",
  secondary_text: "#757575",
  divider: "#EAEBED",
  yellow: "#ffeb3b",
  teal: "#009688",
  purple: "#7E57C2",
  red: "#F44336",
  card: "#FFF",
  grey: "#e0e0e0",
  light_grey: "#eeeeee",
  light_red: "#EF9A9A",
  shadow_level_1: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
  shadow_level_2: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
  shadow_level_3: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
  shadow_level_4: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
  shadow_level_5: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)",
  blue: "#2F6BFF",
  green: "#00BC3C",
};
