import React from "react";
import { Link } from "react-router-dom";

export default function VoteList({ index, display_account, create_time }) {
  return (
    <React.Fragment>
      <tr>
        <td>{index}</td>
        <td>
          <Link to={`/nguoi-binh-chon/${display_account}`} className="link">
            {display_account}
          </Link>
        </td>
        <td>{create_time}</td>
      </tr>
    </React.Fragment>
  );
}
