import axios from "axios";
import moment from "moment";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import io from "socket.io-client";

class ActivityList extends Component {
  state = {
    socket: io.connect("", {
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionAttempts: Infinity,
      transports: ["websocket"],
    }),
    activityList: [],
    activityNumber: 0,
    newActivity: {},
  };

  async componentDidMount() {
    try {
      const res = await axios.get(`/api/get/recently-votes`);

      console.log("voteList", res.data.voteList);

      this.setState({
        activityList: res.data.voteList,
        newActivity: res.data.voteList[0] || {},
      });

      this.state.socket.on("message", (data) => {
        console.log("sk data", data);
        const notifications = this.state.activityList;
        const dataBody = data.body || [];
        const newActivity = dataBody[0];

        this.setState({ activityNumber: notifications.length });
        if (this.state.activityNumber < 5) {
          notifications.push(newActivity);

          this.setState({
            activityList: notifications,
            activityNumber: this.state.activityNumber + 1,
            newActivity,
          });
        } else {
          notifications.pop();
          notifications.unshift(newActivity);

          this.setState({ activityList: notifications, newActivity });
        }
      });
    } catch (error) {
      console.log("xxx Get vote : ", error);
    }
  }

  componentWillUnmount() {
    this.state.socket.disconnect();
  }

  toggleNotifications = () => {
    this.setState({ show: !this.state.show });
  };
  render() {
    const { activityList, newActivity } = this.state;

    let newActivityDetail;

    if (newActivity.txId) {
      // const user = newActivity.tx_id.substr(0, 10);

      const date = moment
        .utc(newActivity.createdAt, [
          "YYYY-MM-DD HH:mm",
          "DD[/]MM[/]YYYY HH:mm",
        ])
        .utcOffset("+0700")
        .format("DD[/]MM[/]YYYY HH:mm");

      newActivityDetail = (
        <tr data-aos="fade-right" key={newActivity.txId}>
          <td>
            <Link to={`/phieu-binh-chon/${newActivity.txId}`} className="link">
              {newActivity._id.slice(0, 20) + "..."}
            </Link>
          </td>
          <td>
            <Link
              to={`/nguoi-binh-chon/${newActivity.voterId}`}
              className="link"
            >
              {newActivity?.voterId?.slice(0, 20) + "..."}
            </Link>
          </td>
          <td>{newActivity.contestantName}</td>
          <td>{date}</td>
        </tr>
      );
    }

    return (
      <React.Fragment>
        {newActivityDetail}

        {activityList.map((activity, index) => {
          if (index === 0) return false;

          // const user = activity.tx_id.substr(0, 10);

          console.log(activity.createdAt);

          const date = moment
            .utc(activity.createdAt, [
              "YYYY-MM-DD HH:mm",
              "DD[/]MM[/]YYYY HH:mm",
            ])
            .utcOffset("+0700")
            .format("DD[/]MM[/]YYYY HH:mm");

          return (
            <tr key={activity.txId}>
              <td>
                <Link to={`/phieu-binh-chon/${activity.txId}`} className="link">
                  {activity._id.slice(0, 20) + "..."}
                </Link>
              </td>
              <td>
                <Link
                  to={`/nguoi-binh-chon/${activity.voterId}`}
                  className="link"
                >
                  {activity.voterId.slice(0, 20) + "..."}
                </Link>
              </td>
              <td>{activity.contestantName}</td>
              <td>{date}</td>
            </tr>
          );
        })}
      </React.Fragment>
    );
  }
}

export default ActivityList;
