import styled from "styled-components";

export const Styles = styled.div`
  #main {
    &.main-result {
      background: white;
      .section-result {
        /* min-height:1080px; */
        padding: 0 0 100px 0;
        background: white;
        .title {
          margin: 74px 0 100px 0;
          h4 {
            text-transform: uppercase;
            color: #153860;
            font-size: 66px;
            font-family: Wylie;
            text-align: center;
            margin: 0;
          }
          h6 {
            text-transform: uppercase;
            color: #1f2839;
            font-size: 32px;
            font-family: Wylie;
            text-align: center;
            margin: 0;
          }
        }
        .top-rank {
          display: flex;
          justify-content: center;
          gap: 60px;
          @media screen and (max-width: 992px) {
            gap: 5px;
          }

          @media only screen and (max-width: 767px) {
            flex-direction: column;
            #rank-1 {
              order: 1;
            }
            #rank-2 {
              order: 2;
            }
            #rank-3 {
              order: 3;
            }

            .top-2 {
              margin-top: 50px !important;
            }

            .top-3 {
              margin-top: 50px !important;
            }
          }

          /* .item-rank{
                            width:200px;
                        } */
          .top-2 {
            margin-top: 50px;
          }
        }
        .rest-rank {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          gap: 30px;
          margin-top: 50px;
          padding: 20px 0;
          @media only screen and (max-width: 992px) {
            margin-top: 40px;
          }
        }
        @media only screen and (max-width: 992px) {
          .title {
            h4 {
              font-size: 45px;
              margin: 20px 0;
            }
            h6 {
            }
          }
        }
        @media only screen and (max-width: 768px) {
          .title {
            h4 {
              font-size: 42px;
              margin: 12px 0;
            }
            h6 {
              font-size: 30px;
            }
          }
        }
        @media only screen and (max-width: 576px) {
          .title {
            h4 {
              font-size: 28px;
              margin: 6px 0;
            }
            h6 {
              font-size: 19px;
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
`;
