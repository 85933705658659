import axios from "axios";
import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import BlockDetail from "./components/Pages/Blockchain/BlockDetail";
import VoteDetail from "./components/Pages/Blockchain/VoteDetail";
import VoterDetail from "./components/Pages/Blockchain/VoterDetail";
import { GroupScreen } from "./components/Pages/group";
import Intro1 from "./components/Pages/Content/Intro1";
import Contestant from "./components/Pages/Contestant/Contestant";
import Index from "./components/Pages/Index/Index";
import Rank from "./components/Pages/Rank/Rank";
import Notification from "./components/Partials/Notification";
import { GlobalStyle } from "./libs/GlobalStyles";
import { connectToSocket, disconnectToSocket } from "./utils/listenSocket";
// import Result from './components/Pages/Content/Result';
import multiLangMes from "./utils/multiLangMes";
import ScrollToTop from "./utils/ScollToTop";

export const ContestantsContext = React.createContext();

export default function App() {
  const [lang, setLang] = useState("vi");
  const [contestants, setContestants] = useState([]);

  const getContestant = async () => {
    try {
      const res = await axios.get(`/api/get/rank`);

      const { rank: listAll } = res.data;
      console.log("==== rank", listAll);
      setContestants(listAll);
    } catch (error) {
      console.log("xxx Get contestants : ", error.message);
    }
  };

  useEffect(() => {
    getContestant();
    const interval = setInterval(() => getContestant(), 120000);
    return () => clearInterval(interval);
  }, []);

  return (
    <ContestantsContext.Provider value={{ contestants }}>
      <GlobalStyle />
      <IntlProvider locale={lang} messages={multiLangMes[lang]}>
        <Router>
          <Switch>
            <ScrollToTop>
              <div className="App">
                <Route
                  exact
                  path="/"
                  render={(props) => (
                    <Index
                      {...props}
                      setLang={setLang}
                      connectToSocket={connectToSocket}
                      disconnectToSocket={disconnectToSocket}
                      lang={lang}
                    />
                  )}
                />

                <Route
                  path="/gioi-thieu"
                  render={(props) => (
                    <Intro1 {...props} setLang={setLang} lang={lang} />
                  )}
                />

                <Route
                  path="/bang-xep-hang"
                  render={(props) => (
                    <Rank {...props} setLang={setLang} lang={lang} />
                  )}
                />

                <Route
                  path="/thi-sinh/:missId"
                  render={(props) => (
                    <Contestant
                      {...props}
                      setLang={setLang}
                      lang={lang}
                      contestants={contestants}
                    />
                  )}
                />

                {/* <Route
                path="/ket-qua"
                render={props => (
                  <Result {...props} setLang={setLang} lang={lang} />
                )}
              /> */}

                <Route
                  path="/phieu-binh-chon/:txId"
                  render={(props) => (
                    <VoteDetail {...props} setLang={setLang} lang={lang} />
                  )}
                />

                <Route
                  path="/khoi/:current/:next"
                  render={(props) => (
                    <BlockDetail {...props} setLang={setLang} lang={lang} />
                  )}
                />
                <Route
                  path="/nguoi-binh-chon/:voterId"
                  render={(props) => (
                    <VoterDetail {...props} setLang={setLang} lang={lang} />
                  )}
                />
                <Notification
                  connectToSocket={connectToSocket}
                  disconnectToSocket={disconnectToSocket}
                />
              </div>
            </ScrollToTop>
          </Switch>
        </Router>
      </IntlProvider>
    </ContestantsContext.Provider>
  );
}
