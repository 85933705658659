import React from "react";
import { FormattedMessage } from "react-intl";
import Slider from "react-slick";
import { ACTIVITY_SLIDE_SLICK } from "../../../config/slide";
import ActivityList from "../../Partials/ActivityList";
import styled from "styled-components";
import { useParams } from "react-router-dom";
export default function SectionActivity() {
  const { table } = useParams();

  return (
    <SectionActivityStyle>
      <section className="section-activity">
        <div className="bs-container">
          <div className="bs-row">
            <div className="bs-col">
              <div className="module module-activity">
                <div className="module-header" data-aos="fade-up">
                  <h2 className="title">
                    <FormattedMessage id="index.recently" />
                  </h2>
                </div>
                <div className="module-content" data-aos="fade-up">
                  <div className="table-content">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>
                            <FormattedMessage id="voteId" />
                          </th>
                          <th>
                            <FormattedMessage id="voterId" />
                          </th>
                          <th>
                            <FormattedMessage id="contestant" />
                          </th>
                          <th>
                            <FormattedMessage id="time" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <ActivityList />
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* <div className="activity-advertisement">
                  <Slider {...ACTIVITY_SLIDE_SLICK} className="activity-slide">
                    <div className="slide-item">
                      <div className="item">
                        <a
                          href="https://pk28bdienbienphu.com/"
                          target="__blank"
                          className="link"
                        >
                          <img
                            src="/images/bv-dbp.jpg"
                            alt="pk28bdienbienphu"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="slide-item">
                      <div className="item">
                        <a
                          href="http://blockchain.bachkhoa-aptech.edu.vn"
                          target="__blank"
                          className="link"
                        >
                          <img
                            src="/images/banner_bytesoft.jpg"
                            alt="lap trinh ung dung blockchain"
                          />
                        </a>
                      </div>
                    </div>
                  </Slider>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </SectionActivityStyle>
  );
}
export const SectionActivityStyle = styled.div`
  .section-activity {
    background-image: none;
    .module-activity {
      .module-header {
        .title {
          position: relative;
          max-width: 317px;
          left: 7.5px;
          top: 0px;
          font-family: "Baloo_2";
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 150%;
          color: #153860;
        }
        .title:before {
          background: none;
          background-image: url("../images/Vector_5.png");
          background-position: top center;
          background-repeat: no-repeat;
          background-size: contain;
          width: 333px;
          height: 56px;
          left: 28.5px;
          top: 33px;
          position: absolute;
        }
        .title:after {
          background: none;
          background-image: url("../images/Vector_6.png");
          background-position: top center;
          background-repeat: no-repeat;
          background-size: contain;
          width: 50px;
          height: 41px;
          left: 291.5px;
          top: 0px;
        }
      }
    }
    .module-content {
      margin-top: 0;
      box-shadow: none;
      .table-content {
        border-top: none;
        overflow: auto;
        .table {
          border-collapse: separate;
          border-spacing: 0 20px;
          thead {
            th {
              font-family: "Be Vietnam Pro";
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 27px;
              color: #344050;
              text-align: unset;
              align-items: flex-end;
              padding-left: 30px;
              text-transform: capitalize;
            }
          }
        }
        tbody {
          position: relative;
          tr {
            position: relative;
            td {
              background: rgba(9, 121, 229, 0.05);
              border-radius: 8px;
              position: relative;
              font-family: "Be Vietnam Pro" !important;
              font-style: normal;
              font-weight: 500 !important;
              font-size: 16px !important;
              line-height: 150%;
              color: #344050 !important;
              text-transform: capitalize !important;
              position: relative;
              a {
                font-family: "Be Vietnam Pro";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 150%;
                color: #176cdd;
              }
            }
            td::after {
              content: "";
              position: absolute;
              display: block;
              width: 24px;
              height: 0px;
              border: 1px dashed #717984;
              transform: rotate(90deg);
              right: -12px;
              top: 32px;
            }
            td:last-child::after {
              display: none;
            }
          }
          tr:nth-child(even) {
            background: #fff5ed !important;
            border-radius: 8px;
          }
        }
      }
    }
  }
`;
