export const configId = (missId) => {
  let configId = missId;
  for (let j = 1; j < 1; j++) {
    if (configId && configId.length < 1) {
      configId = "0" + configId;
    }
  }
  return configId;
};

export const getAvatarUrl = (missId) => {
  return `https://cdn.bvote.vn/maunhi2023c/Avatar/${configId(missId)}.jpg`;
};

export const getDetailImage = (missId) => {
  return `https://cdn.bvote.vn/maunhi2023c/Detail/${configId(missId)}.jpg`;
};
