import React from "react";
import styled from "styled-components";
import { useEffect } from "react";
import { getAvatarUrl } from "../../libs/index";
import { Link } from "react-router-dom";
// {`/images/contestant/avatar/${configId}.jpg`}
export const ItemRank = ({
  stars,
  name,
  rank,
  image,
  miss,
  table,
  ...props
}) => {
  let configId = miss?.code;

  console.log(configId);
  return (
    <Link to={`/thi-sinh/${miss?._id}`} id={`rank-${rank}`}>
      <Styles>
        <div
          className={`rank-item__box ${
            rank == 2 ? "top-2" : rank == 3 ? "top-3" : ""
          }`}
        >
          <div className={`image ${rank == 1 ? "top-1" : ""}`}>
            <span>
              {rank === 1 && <img className="king" src={`/images/king.png`} />}
              {rank === 1 ? (
                <div className="rank-top">
                  <p>{rank}</p>
                  <img src={`/images/rank-top.png`} />
                </div>
              ) : (
                <div className="rest-rank-top">
                  <p>{rank}</p>
                  {/* <img src={`/images/rank-top.png`}/> */}
                </div>
              )}
            </span>
            <img
              className={`avatar ${rank === 1 ? "top-1" : ""}`}
              src={getAvatarUrl(configId)}
            />
            {/* <img className='avatar' src={`/images/contestant/avatar/${configId}.jpg`}/> */}
            {/* <img src="/images/a1.png" className={`avatar ${rank === 1 ? "top-1" : ""}`}/> */}
          </div>
          <div className="stars">
            <p>{miss?.totalPoint} </p>
            <img src="/images/diamond.png" />
          </div>
          <h6 className="name">{miss?.fullname}</h6>
        </div>
      </Styles>
    </Link>
  );
};

const Styles = styled.div`
  .rank-item__box {
    /* margin:0 60px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    &.1 {
    }
    &.top-2 {
      margin-top: 280px !important;
    }
    &.top-3 {
      margin-top: 300px !important;
    }

    .image {
      /* background-image:url('/images/rank-border.png'); */
      position: relative;
      max-width: 244px;
      min-width: 244px;
      min-height: 244px;
      max-height: 244px;
      display: flex;
      justify-content: center;
      &.top-1 {
        max-width: 324px;
      }
      span {
        position: absolute;
        width: 100%;
        height: 100%;
        /* top:0;
                left:50%; */

        img {
          &.king {
            /* width:100%; */
            position: absolute;
            transform: translate(-50%, -70px);
            top: 0%;
            left: 50%;
          }
        }
        .rank-top {
          position: absolute;
          width: 69px;
          height: 67px;
          right: 28px;
          bottom: 0%;
          display: flex;
          align-item: center;
          justify-content: center;
          p {
            margin: 0;
            font-size: 20px;
            color: white;
            position: absolute;
            text-align: center;
            top: 50%;
            transform: translateY(-50%);
            font-size: 32px;
            line-height: 48px;
            font-weight: 700;
          }
        }
        .rest-rank-top {
          position: absolute;
          bottom: 12px;
          right: 12px;
          width: 42px;
          height: 42px;
          border-radius: 50%;
          padding: 3px;
          background: white;
          p {
            background: #cb8b28;
            color: white;
            font-family: Be Vietnam Pro;
            font-size: 20px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
      .avatar {
        width: 100%;
        border-radius: 50%;
        /* width:244px; */
        border: 1px solid #cb8b28;
        padding: 7px;
        &.top-1 {
          border: 8px solid #cb8b28;
          padding: 0;
        }
      }

      &:hover {
        background: rgba(0, 0, 0, 0.05);
        box-shadow: -4px 4px 20px #cb8b28;
        transform: scale(1.015) translate(5px, -5px);
        border: 1px solid white;
        border-radius: 50%;
        &::before {
          top: 8px;
          left: -8px;
        }
      }
    }
    .stars {
      justify-content: center;
      color: white;
      display: flex;
      align-items: center;
      p {
        margin: 0;
        color: #1f2839;
        font-weight: 700;
        font-size: 36px;
        font-family: Be Vietnam Pro_Bold;
      }
      img {
        padding-bottom: 8px;
        margin-left: 4px;
      }
    }
    .name {
      font-size: 28px;
      font-weght: 400;
      letter-spacing: 3px;
      text-align: center;
      font-family: Wylie;
    }
    @media only screen and (max-width: 992px) {
      .stars {
        margin-top: 12px;
        p {
          font-size: 25px;
        }
        img {
          padding-bottom: 4px;
          margin-left: 4px;
        }
      }
      .name {
        font-size: 15px;
        font-weight: 700;
      }
    }
    @media only screen and (max-width: 768px) {
      .stars {
      }
      .name {
        font-size: 15px;
        font-weight: 700;
      }
    }
    @media only screen and (max-width: 576px) {
      &.1 {
      }
      &.top-2 {
        margin-top: 100px !important;
      }
      &.top-3 {
        margin-top: 100px !important;
      }
      .image {
        max-width: 100px;
        &.top-1 {
          max-width: 160px;
        }
        span {
          img {
            &.king {
              /* width:100%; */
              position: absolute;
              transform: translate(-50%, 0);
              bottom: 100%;
              top: unset;
              left: 50%;
              width: 50px;
            }
          }
          .rank-top {
            position: absolute;
            width: 49px;
            height: 47px;
            right: 50%;
            bottom: -10px;
            transform: translateX(50%);
            display: flex;
            align-item: center;
            justify-content: center;
            p {
              margin: 0;
              color: white;
              position: absolute;
              text-align: center;
              top: 50%;
              transform: translateY(-50%);
              font-size: 15px;
              /* line-height:48px; */
              font-weight: 700;
            }
          }
          .rest-rank-top {
            position: absolute;
            bottom: 0;
            right: 50%;
            transform: translateX(50%);
            width: 32px;
            height: 32px;
            border-radius: 50%;
            padding: 3px;
            background: white;
            p {
              background: #cb8b28;
              color: white;
              font-family: Be Vietnam Pro;
              font-size: 14px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0;
              width: 100%;
              height: 100%;
            }
          }
        }
        .avatar {
          box-shadow: 1px 1px 10px #cb8b28;
        }
      }

      .stars {
        margin-top: 15px;
        p {
          font-size: 15px;
        }
      }
      .name {
        font-size: 13px;
        font-weight: 700;
      }
    }
  }
`;
