import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import BaseLayout from './BaseLayout';
import PageBanner from './PageBanner';

export default function ContentLayout({
  title,
  description,
  canonical,
  pageTitle,
  content,
  children,
  setLang,
}) {
  return (
    <BaseLayout
      title={title}
      description={description}
      canonical={canonical}
      setLang={setLang}
    >
      <main id="main">
        <PageBanner />
        <section className="section-rules">
          <div className="bs-container">
            <div className="bs-row">
              <div className="bs-col">
                <div className="module module-rules">
                  <div className="module-content">
                    <div className="head">
                      <h1 className="title">
                        <strong>{pageTitle}</strong>
                      </h1>
                    </div>
                    <div className="body" style={{ color: 'black' }}>
                      {ReactHtmlParser(content)}
                      {children}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </BaseLayout>
  );
}
