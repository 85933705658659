import React from "react";
import styled from "styled-components";

export const Header = ({ label, ...props }) => {
  return (
    <Styles {...props}>
      <div>{label}</div>
      <img
        className="close-modal"
        src={closeIcon}
        onClick={() => props.close && props.close()}
        alt="close-modal"
      />
    </Styles>
  );
};

const Styles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 500;
  padding: 16px 15px 12px 20px;
  border-bottom: 1px solid #eaebed;
  img {
    cursor: pointer;
  }
`;

export const closeIcon = (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="2.057"
      height="20.57"
      transform="matrix(0.707099 -0.707115 0.707099 0.707115 0.0849609 1.88086)"
      fill="#FF5576"
    />
    <rect
      width="2.057"
      height="20.57"
      transform="matrix(0.707099 0.707115 -0.707099 0.707115 14.6299 0.425781)"
      fill="#FF5576"
    />
  </svg>
);
