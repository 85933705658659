import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <Styles>
      <div className="bs-container">
        <div className="footer-logo">
          <img src="/images/logo-white.png" />
        </div>
        <div className="footer-nav">
          <div className="footer-left">
            <Link to="/">Trang chủ</Link>
            <Link to="/gioi-thieu">Giới thiệu</Link>
            <Link to="/bang-xep-hang/1">Bảng xếp hạng</Link>
          </div>
          <div className="footer-right">
            Copyright ©2022 BVote. All rights reserved.
          </div>
        </div>
      </div>
      <img className="student-image" src="/images/students.png" />
    </Styles>
  );
}

const Styles = styled.div`
  background: url("/images/footer.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  color: white;
  position: relative;
  padding: 129px 0 55px;

  .footer-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    font-family: Baloo_2;

    .footer-left {
      display: flex;
      align-items: center;
      gap: 30px;

      a {
        font-size: 18px;
        :hover {
          color: unset;
        }
      }
    }

    .footer-right {
      text-align: center;
    }
  }
  .student-image {
    position: absolute;
    right: 25px;
    bottom: 143px;
    z-index: 10;
  }

  @media only screen and (max-width: 768px) {
    .footer-nav {
      flex-direction: column;
      gap: 15px;
    }
  }

  @media only screen and (max-width: 480px) {
    .student-image {
      display: none;
    }
  }
`;
